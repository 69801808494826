
import { ref, defineComponent } from 'vue'
import { MqResponsive } from 'vue3-mq'

import AsMenu from '@web/components/AsMenu.vue'
import AsRow from '@web/components/AsRow.vue'
import AsHeaderMain from '@web/components/headers/AsHeaderMain.vue'
import AsFooter from '@web/components/AsFooter.vue'
import AsSpacer from '@web/components/AsSpacer.vue'
import AsBreadcrumb from '@web/components/AsBreadcrumb.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsBanner from '@web/components/AsBanner.vue'

import usePage from '@web/composition/usePage'
import AsHeaderMobile from '@web/components/headers/AsHeaderMobile.vue'

export default defineComponent({
  components: {
    AsHeaderMobile,
    MqResponsive,
    AsMenu,
    AsRow,
    AsHeaderMain,
    AsSpacer,
    AsFooter,
    AsBreadcrumb,
    AsPreloader,
    AsBanner
  },
  props: {
    title: {
      type: String,
      require: true
    },
    loaded: {
      type: Boolean,
      default: true
    },
    prevPage: {
      type: [String, Object],
      default: null
    }
  },
  setup () {
    const isOpenMenu = ref(false)
    return {
      isOpenMenu,
      ...usePage()
    }
  }
})
