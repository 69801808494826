
import { defineComponent } from 'vue'

import Page from '@web/views/Page.vue'
import AsVideoConsultationInfo from '@web/components/AsVideoConsultationInfo.vue'

import useVideoConsultation from '@web/composition/useVideoConsultation'

import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'

export default defineComponent({
  components: {
    Page,
    AsVideoConsultationInfo
  },
  setup () {
    const router = useRouter()
    const {
      title
    } = useVideoConsultation({ router })

    useMeta({
      title
    })

    return {
      title
    }
  }
})
