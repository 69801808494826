import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "lCommon" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_header_mobile = _resolveComponent("as-header-mobile")!
  const _component_as_preloader = _resolveComponent("as-preloader")!
  const _component_mq_responsive = _resolveComponent("mq-responsive")!
  const _component_as_banner = _resolveComponent("as-banner")!
  const _component_as_menu = _resolveComponent("as-menu")!
  const _component_as_row = _resolveComponent("as-row")!
  const _component_as_breadcrumb = _resolveComponent("as-breadcrumb")!
  const _component_as_header_main = _resolveComponent("as-header-main")!
  const _component_as_spacer = _resolveComponent("as-spacer")!
  const _component_as_footer = _resolveComponent("as-footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_mq_responsive, {
      target: "mobile",
      class: "smPage"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_mobile, {
          "prev-page": _ctx.prevPage,
          title: _ctx.title,
          back: "",
          "onMenu:toggle": _ctx.isOpenMenu
        }, null, 8, ["prev-page", "title", "onMenu:toggle"]),
        (_ctx.loaded && !_ctx.isOpenMenu)
          ? _renderSlot(_ctx.$slots, "sm", { key: 0 })
          : _withDirectives((_openBlock(), _createBlock(_component_as_preloader, { key: 1 }, null, 512)), [
              [_vShow, !_ctx.isOpenMenu]
            ])
      ]),
      _: 3
    }),
    _createVNode(_component_mq_responsive, {
      target: "tablet",
      class: "mdPage"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.bannersTop, (banner, i) => {
          return (_openBlock(), _createBlock(_component_as_banner, {
            key: `bannerTop${i}`,
            banner: banner,
            position: "top"
          }, null, 8, ["banner"]))
        }), 128)),
        _createVNode(_component_as_row, null, {
          sidebar: _withCtx(() => [
            _createVNode(_component_as_header_mobile, {
              title: _ctx.title,
              back: ""
            }, null, 8, ["title"]),
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              status: _ctx.status
            }, null, 8, ["menu", "status"])
          ]),
          content: _withCtx(() => [
            (_ctx.loaded)
              ? _renderSlot(_ctx.$slots, "md", { key: 0 })
              : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
          ]),
          _: 3
        })
      ]),
      _: 3
    }),
    _createVNode(_component_mq_responsive, {
      class: "lCommon bg-blue",
      target: "desktop"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_as_header_main, null, {
          breadcrumb: _withCtx(() => [
            _createVNode(_component_as_breadcrumb, { title: _ctx.title }, null, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_as_row, null, {
          menu: _withCtx(() => [
            _createVNode(_component_as_menu, {
              menu: _ctx.menu,
              type: "sidebar"
            }, null, 8, ["menu"]),
            _renderSlot(_ctx.$slots, "desktopSidebarBanners")
          ]),
          content: _withCtx(() => [
            _renderSlot(_ctx.$slots, "desktopTopBanners"),
            _createVNode(_component_as_row, { class: "-inner lgPage" }, {
              content: _withCtx(() => [
                (_ctx.loaded)
                  ? _renderSlot(_ctx.$slots, "lg", { key: 0 })
                  : (_openBlock(), _createBlock(_component_as_preloader, { key: 1 }))
              ]),
              _: 3
            }),
            _renderSlot(_ctx.$slots, "desktopBottomBanners")
          ]),
          _: 3
        }),
        _createVNode(_component_as_spacer),
        _createVNode(_component_as_footer)
      ]),
      _: 3
    })
  ]))
}