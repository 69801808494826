import { computed } from 'vue'

import { useStore } from '@web/store'
import i18n from '@web/plugins/i18n'

export default function () {
  const store = useStore()
  const user = computed(() => store.getters['user/user'])
  const username = computed(() => store.getters['user/username'])
  const menu = computed(() => store.getters['menu/general'])
  const bannersTop = computed(() => store.getters['banner/coursesTop'])
  const bannersSidebar = computed(() => store.getters['banner/coursesSidebar'])
  const bannersBottom = computed(() => store.getters['banner/coursesBottom'])
  const toolbarButton = computed(() => {
    return {
      name: i18n.global.t('backToAccount'),
      url: '/user',
      classes: '-back'
    }
  })

  return {
    bannersTop,
    bannersBottom,
    bannersSidebar,
    user,
    username,
    toolbarButton,
    status,
    menu
  }
}
