import { ref, computed } from 'vue'
import Swal from 'sweetalert2'
import Time from '@web/common/Time'
import VideoConsultationStatusConst from '@web/consts/VideoConsultationStatus'
import { useStore } from '@web/store'
import { IS_MOBILE } from '@web/common/config'
import i18n from '@web/plugins/i18n'

const store = useStore()

interface Form {
  id: number;
  phone: string;
  date: string;
  time: string;
  note: string;
}

export default function ({ router }) {
  const isShowCancelModal = ref(false)
  const loaded = ref(false)
  const localStatus = ref<string>('')
  const formPlanning = ref<Form>({
    id: 0,
    phone: '',
    date: '',
    time: '',
    note: ''
  })
  const title = computed(() => String(i18n.global.t('videoConsultations.VideoConsultation')))
  const id = computed(() => parseInt(router.currentRoute.value?.params?.id, 10))
  const videoConsultation = computed(() => store.getters['videoConsultation/getPurchasedVideoConsultationsById'](id.value))
  const videoConsultationStatus = computed<string | undefined>(() => localStatus.value ? localStatus.value : videoConsultation.value?.status)
  const videoConsultationHref = computed<string | null>(() => videoConsultation.value?.url?.length ? videoConsultation.value.url : null)
  const videoConsultationTarget = computed<string | null>(() => videoConsultation.value?.url?.length ? '_blank' : null)
  const videoConsultationStartDateTime = computed<string | undefined>(() => videoConsultation.value?.startDateTime)
  const user = computed(() => store.getters['user/user'])
  const loadedInfo = computed(() => {
    return loaded && id.value && videoConsultation.value
  })
  const formPlanningData = computed(() => {
    const phone = formPlanning.value.phone.length ? formPlanning.value.phone : videoConsultation.value?.phone?.length ? videoConsultation.value.phone : user.value.phone
    const date = formPlanning.value.date.length ? formPlanning.value.date : videoConsultationStartDateTime.value ? Time(videoConsultationStartDateTime.value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') : ''
    const time = formPlanning.value.time.length ? formPlanning.value.time : videoConsultationStartDateTime.value ? Time(videoConsultationStartDateTime.value, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss') : ''
    const note = formPlanning.value.note.length ? formPlanning.value.note : videoConsultation.value?.note || ''
    return {
      id: id.value,
      phone,
      date,
      time,
      note
    }
  })

  const fetchPurchasedVideoConsultations = () => store.dispatch('videoConsultation/fetchPurchasedVideoConsultations')
  const updateVideoConsultations = (payload) => store.dispatch('videoConsultation/updateVideoConsultations', payload)

  async function fetchData () {
    await fetchPurchasedVideoConsultations()
  }

  function showErrorAlert () {
    Swal.fire({
      icon: 'error',
      title: String(i18n.global.t('error.error')),
      text: String(i18n.global.t('videoConsultations.linkNotAvailable'))
    })
  }

  function goBack () {
    localStatus.value = ''
    router.push({ name: 'videoconsultations' })
  }

  function consultationPlanning () {
    const time = Time(`${formPlanningData.value.date} ${formPlanningData.value.time}`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm:ss')
    updateVideoConsultations({
      id: formPlanningData.value.id,
      phone: formPlanningData.value.phone,
      time,
      note: formPlanningData.value.note
    }).then(() => {
      goBack()
      Swal.fire({
        icon: 'success',
        title: `${String(i18n.global.t('successfully'))}!`,
        showConfirmButton: true
      })
    })
  }

  const isCreated = computed(() => VideoConsultationStatusConst.CREATED === videoConsultationStatus.value)
  const isPlanned = computed(() => VideoConsultationStatusConst.PLANNED === videoConsultationStatus.value)
  const isApproved = computed(() => VideoConsultationStatusConst.APPROVED === videoConsultationStatus.value)
  const isDone = computed(() => VideoConsultationStatusConst.DONE === videoConsultationStatus.value)
  const isCanceled = computed(() => VideoConsultationStatusConst.CANCELED === videoConsultationStatus.value)
  const isRefund = computed(() => VideoConsultationStatusConst.REFUND === videoConsultationStatus.value)
  const isCreatedOrCanceled = computed(() => isCreated.value || isCanceled.value)
  const isDoneOrRefund = computed(() => isDone.value || isRefund.value)

  function getDate (startDateTime) {
    return Time(startDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD MMMM YYYY')
  }

  function getTime (startDateTime) {
    return Time(startDateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm')
  }

  function changeDateTime () {
    formPlanning.value = {
      id: id.value,
      phone: videoConsultation.value?.phone?.length ? videoConsultation.value.phone : user.value.phone,
      date: Time(videoConsultationStartDateTime.value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD'),
      time: Time(videoConsultationStartDateTime.value, 'YYYY-MM-DD HH:mm:ss').format('HH:mm'),
      note: videoConsultation.value?.note || ''
    }
    localStatus.value = VideoConsultationStatusConst.CREATED
    isShowCancelModal.value = false
  }

  function cancelConsultationByTechSupport () {
    router.push({ name: 'support' })
  }

  function goToMeeting () {
    if (!videoConsultationHref.value) {
      showErrorAlert()

      return
    }
    if (IS_MOBILE) {
      window.open(videoConsultationHref.value, '_system')
    }
  }

  return {
    id,
    isShowCancelModal,
    loaded,
    loadedInfo,
    title,
    formPlanning,
    formPlanningData,
    videoConsultation,
    videoConsultationStatus,
    videoConsultationHref,
    videoConsultationTarget,
    videoConsultationStartDateTime,

    fetchPurchasedVideoConsultations,
    updateVideoConsultations,
    fetchData,
    showErrorAlert,
    consultationPlanning,
    isCreated,
    isPlanned,
    isApproved,
    isDone,
    isCanceled,
    isRefund,
    isCreatedOrCanceled,
    isDoneOrRefund,
    getDate,
    getTime,
    changeDateTime,
    cancelConsultationByTechSupport,
    goBack,
    goToMeeting
  }
}
