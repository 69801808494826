import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_as_video_consultation_info = _resolveComponent("as-video-consultation-info")!
  const _component_page = _resolveComponent("page")!

  return (_openBlock(), _createBlock(_component_page, {
    title: _ctx.title,
    prevPage: { name: 'videoconsultations' }
  }, {
    sm: _withCtx(() => [
      _createVNode(_component_as_video_consultation_info)
    ]),
    md: _withCtx(() => [
      _createVNode(_component_as_video_consultation_info)
    ]),
    lg: _withCtx(() => [
      _createVNode(_component_as_video_consultation_info)
    ]),
    _: 1
  }, 8, ["title"]))
}