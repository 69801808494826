
import { defineComponent, onMounted, ref } from 'vue'

import { Form as VForm, Field as VField } from 'vee-validate'

import AsModal from '@web/components/AsModal.vue'
import AsInput from '@web/components/AsInput/AsInput.vue'
import AsTextarea from '@web/components/AsTextarea.vue'
import AsPreloader from '@web/components/AsPreloader.vue'
import AsDateTimePicker from '@web/components/AsDateTimePicker.vue'

import useVideoConsultation from '@web/composition/useVideoConsultation'
import useErrors from '@web/composition/useErrors'

import sentry from '@web/common/sentry'
import { performanceEnd } from '@web/common/Utils'
import Logger from '@web/common/Logger'
import FirebasePerformance from '@web/services/firebasePerformance'
import { useRouter } from 'vue-router'
import { useMeta } from '@web/common/vueMeta'
import Time from '@web/common/Time'

export default defineComponent({
  components: {
    AsModal,
    AsInput,
    AsTextarea,
    VForm,
    VField,
    AsPreloader,
    AsDateTimePicker
  },
  setup () {
    const router = useRouter()
    const minDate = ref(Time().format('YYYY-MM-DD'))

    const {
      id,
      isShowCancelModal,
      loaded,
      loadedInfo,
      title,
      formPlanning,
      formPlanningData,
      videoConsultation,
      videoConsultationHref,
      videoConsultationTarget,
      fetchData,
      showErrorAlert,
      consultationPlanning,
      isCreated,
      isPlanned,
      isApproved,
      isDone,
      isCanceled,
      isRefund,
      isCreatedOrCanceled,
      isDoneOrRefund,
      getDate,
      getTime,
      changeDateTime,
      cancelConsultationByTechSupport,
      goBack,
      goToMeeting
    } = useVideoConsultation({ router })

    useMeta({
      title
    })

    const {
      serverErrors,
      getFirstError
    } = useErrors()

    async function init () {
      let videoConsultationPageLoading = performance.now()
      const startLoading = Date.now()
      try {
        loaded.value = false
        await fetchData()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        sentry.captureException(e)
        serverErrors.value = e.errors || []
        showErrorAlert()
        Logger.error(e)
      } finally {
        videoConsultationPageLoading = performanceEnd(videoConsultationPageLoading)
        Logger.info(`Video Consultation #${id.value} loaded: ${videoConsultationPageLoading} ms`)
        loaded.value = true
        FirebasePerformance.record({
          traceName: 'load_videoconsultation',
          startTime: startLoading,
          options: {
            attributes: {
              videoConsultationId: videoConsultation.value?.id.toString() || ''
            }
          }
        })
      }
    }

    onMounted(() => {
      init()
    })

    return {
      minDate,
      isShowCancelModal,
      loaded,
      loadedInfo,
      title,
      formPlanning,
      formPlanningData,
      videoConsultation,
      videoConsultationHref,
      videoConsultationTarget,
      consultationPlanning,
      isCreated,
      isPlanned,
      isApproved,
      isDone,
      isCanceled,
      isRefund,
      isCreatedOrCanceled,
      isDoneOrRefund,
      getDate,
      getTime,
      changeDateTime,
      cancelConsultationByTechSupport,
      goBack,
      goToMeeting,

      getFirstError
    }
  }
})
